import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import { MonacoEditor } from "./MonacoEditor";

function MonacoEditorStandalone(
	{ maximizeClassList, maximizeTarget, previewTarget, textarea }:
	{ maximizeClassList: string; maximizeTarget?: HTMLElement | null; previewTarget?: HTMLElement | null; textarea: HTMLTextAreaElement }) {
	const [maximized, setMaximized] = useState(false);

	useEffect(() => {
		if (!maximizeTarget) {
			return;
		}

		maximizeTarget.classList.value = maximized
			? "p-4 bg-body maximized-editor d-flex flex-column"
			: maximizeClassList;
	}, [maximizeClassList, maximizeTarget, maximized]);

	useEffect(() => {
		if (!previewTarget) {
			return;
		}

		if (maximized) {
			previewTarget.classList.add("container");
		} else {
			previewTarget.classList.remove("container");
		}
	}, [previewTarget, maximized]);

	return (
		<MonacoEditor
			id="monaco-editor"
			className=""
			copyToClipboard={true}
			height={800}
			language={textarea.getAttribute("monaco-lang") ?? undefined}
			maximized={maximized}
			value={textarea.textContent ?? ""}
			onChange={value => textarea.textContent = value}
			setMaximized={setMaximized}
		/>
	);
}

function renderEditor(textarea: HTMLTextAreaElement) {
	const contentEditor = document.createElement("div");
	contentEditor.classList.add("flex-grow-1");

	textarea.after(contentEditor);
	textarea.style.display = "none";

	const maximizeTargetAttribute = textarea.getAttribute("maximize-target");
	const maximizeTarget = maximizeTargetAttribute ? document.querySelector<HTMLElement>(maximizeTargetAttribute) : null;
	const maximizeClassList = maximizeTarget?.classList.value ?? "";
	const monacoEditorWorkerUrl = textarea.getAttribute("monaco-worker-editor") ?? "";
	const monacoHtmlWorkerUrl = textarea.getAttribute("monaco-worker-html") ?? "";
	const monacoJsonWorkerUrl = textarea.getAttribute("monaco-worker-json") ?? "";
	const previewTargetAttribute = textarea.getAttribute("preview-target");
	const previewTarget = previewTargetAttribute ? document.querySelector<HTMLElement>(previewTargetAttribute) : null;

	self.MonacoEnvironment = {
		createTrustedTypesPolicy() {
			return undefined;
		},
		getWorkerUrl(_moduleId: string, label: string) {
			if (label === "html") {
				return monacoHtmlWorkerUrl;
			}

			if (label === "json") {
				return monacoJsonWorkerUrl;
			}

			return monacoEditorWorkerUrl;
		}
	};

	try {
		const root = createRoot(contentEditor);
		root.render(
			<MonacoEditorStandalone
				maximizeClassList={maximizeClassList}
				maximizeTarget={maximizeTarget}
				previewTarget={previewTarget}
				textarea={textarea}
			/>
		);
	} catch (error) {
		contentEditor.style.display = "none";
		textarea.style.display = "";
		throw error;
	}
}

function toArray<T extends Element>(nodeList: ReturnType<ParentNode["querySelectorAll"]>) {
	return Array.prototype.slice.call(nodeList, 0) as T[];
}

for (const textarea of toArray<HTMLTextAreaElement>(document.querySelectorAll("textarea.monaco"))) {
	renderEditor(textarea);
}
